import { Link } from 'gatsby';
import React from 'react';
import Grid from '../../components/grid/grid';
import GridItem from '../../components/grid/item';
import Hero from '../../components/hero/hero';
import Layout from '../../components/layout';
// import BamPdf from '../../pdfs/BAM-2016-Script-Template.pdf';
// import MkPdf from '../../pdfs/M-K-2016-Prescription-Form.pdf';
import GeneralPdf from '../../pdfs/zoopharm_prescription_form_2020.pdf';

const OnlineRefillsPage = () => (
  <Layout title="Online Refills">
    <Hero backgroundImage="zoo-pharm" headline="Online Refills" tagline="" />
    <section className="content-wrapper">
      <Grid justifyContent="center" alignItems="center">
        <GridItem md={8}>
          <h2>Please follow the instructions below for online refills.</h2>
          <p>
            ZooPharm Compounding Veterinary Pharmacy welcomes you to place orders online using our
            website.
            <br />
            However, in order to fully process and properly fill your order, a prescription must be
            provided by your prescribing veterinarian.
          </p>
          <h3>For Refills follow these instructions:</h3>
          <ul>
            <li>
              Only use our ONLINE REFILL FORM if you have previously received medication from
              ZooPharm.
              <br />
              NOTE: Prescriptions for controlled substances are only valid for 6 months from the
              date on the Rx submitted.
            </li>
            <li>
              If you require any changes to your requested medication itself, please do not use this
              form and instead call us at
              <strong>866 823 9314</strong>
            </li>
            <li>
              When completing the online form, please ensure your Rx specifies the patient name OR
              single species for whom the medication is being prescribed, in compliance with your
              State requirements.
              <br />
              NOTE: If prescribing for more than one species, multiple prescriptions will be
              required.
            </li>
          </ul>
          <Link to="/online-refills/refill-form/" className="c-button--primary float-right">
            Refill Form
          </Link>
        </GridItem>
        <GridItem md={8}>
          <h3>Submission of Prescription Orders:</h3>
          <p>
            Please download our PRESCRIPTION FORM and ask your veterinarian to complete and submit
            to ZooPharm via the following methods:
          </p>
          <ul>
            <li>
              Fax to: 307-761-6060
              <br />
              PLEASE NOTE: The prescription form must be faxed directly from your veterinarian’s
              office with a photocopy of appropriate State and/or Federal licensure.
            </li>
            <li>
              Mail the original prescription form to 3131 Grand Avenue, Unit C, Laramie, WY 82070.
              NOTE: Your veterinarian may call our pharmacy to authorize a new verbal prescription
              only if he/she has prescribed with ZooPharm in the past and we have their signature
              and licensure on file.
            </li>
          </ul>
          <Grid justifyContent="space-between" alignItems="center">
            {/* <a
              href={BamPdf}
              target="_blank"
              rel="noopener noreferrer"
              className="c-button--primary float-right"
            >
              BAM Kit
            </a> */}
            <a
              href={GeneralPdf}
              target="_blank"
              rel="noopener noreferrer"
              className="c-button--primary float-right"
            >
              General ZooPharm
            </a>
            {/* <a
              href={MkPdf}
              target="_blank"
              rel="noopener noreferrer"
              className="c-button--primary float-right"
            >
              MK Kit
            </a> */}
          </Grid>
        </GridItem>
      </Grid>
    </section>
  </Layout>
);

export default OnlineRefillsPage;
